import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditableTableRow({
  label,
  value,
  name,
  onChange,
  isEditMode,
  isDate = false,
  isDropdown = false,
  options = [],
  dateFormat = "MM/dd/yyyy",
  isLeader = false,
  leaderId = '',
  editLink = ''
}) {
  // Custom onChange handler for DatePicker
  const handleDateChange = (date) => {
    onChange({ target: { name, value: date } });
  };

  // Enhanced dropdown change handler
  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    if (value === "") {
      // Handle the "Choose..." option
      onChange({
        target: {
          name,
          value: null,
          idKey: `${name}_Id`,
          idValue: null
        }
      });
      return;
    }

    const selectedOption = options.find(option => option.employee_full_name === value);
    if (selectedOption) {
      // Create a synthetic event that includes both the display value and ID
      onChange({
        target: {
          name,
          value: selectedOption.employee_full_name,
          idKey: `${name}_Id`,
          idValue: selectedOption.id
        }
      });
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "No data";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "No data";
    }
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Handle leader fields
  if (isLeader && isEditMode) {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left" }}>{label}:</td>
          <td style={{ textAlign: "right" }}>
            <input
              type="text"
              name={name}
              value={value || ''}
              onChange={onChange}
              placeholder={`Enter ${label}`}
            />
          </td>
        </tr>
        {leaderId && (
          <tr>
            <td>Edit:</td>
            <td><a href={editLink}>Edit {label}</a></td>
          </tr>
        )}
      </>
    );
  }

  // Render normal fields
  return (
    <tr>
      <td style={{ textAlign: "left" }}>{label}:</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {isEditMode ? (
          isDate ? (
            <DatePicker
              selected={value ? new Date(value) : null}
              onChange={handleDateChange}
              dateFormat={dateFormat}
              name={name}
              wrapperClassName="datePicker"
            />
          ) : isDropdown ? (
            <select 
              name={name} 
              value={value || ""} 
              onChange={handleDropdownChange}
              className="dropdown-select"
            >
              <option value="">Choose...</option>
              {options.map(option => (
                <option 
                  key={option.id} 
                  value={option.employee_full_name}
                  data-id={option.id}
                >
                  {option.employee_full_name}
                </option>
              ))}
            </select>
          ) : (
            <input 
              type="text" 
              name={name} 
              value={value || ''} 
              onChange={onChange} 
            />
          )
        ) : (
          isDate ? formatDate(value) : (value || 'No data')
        )}
      </td>
    </tr>
  );
}

export default EditableTableRow;
