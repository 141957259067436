import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/general/AuthContext';
import axios from 'axios'; // For API requests
import Staff from './components/staff/Staff';
import EmployeeDetails from './components/staff/EmployeeDetails';
import BargainingUnits from './components/units/BargainingUnits';
import BargainingUnitInfo from './components/units/BargainingUnitInfo';
import Towns from './components/towns/Towns';
import Town from './components/towns/Town';
import UnassignedUnits from './components/units/ManageLocals';
import ScrollToTop from './components/general/ScrollToTop';
import Metrics from './components/metrics/Metrics';
import AuditLog from './components/audit/AuditLog';
import Login from './components/general/Login.js';
import { initGA, logPageView } from './analytics';

const App = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Validate session on page load
  useEffect(() => {
    const validateSession = async () => {
      try {
        const response = await axios.get('/api/protected', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Session validation failed:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    validateSession();
  }, []);

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={isAuthenticated ? <Metrics /> : <Navigate to="/login" />}
          />
          <Route
            path="/metrics"
            element={isAuthenticated ? <Metrics /> : <Navigate to="/login" />}
          />
          <Route
            path="/staff"
            element={isAuthenticated ? <Staff /> : <Navigate to="/login" />}
          />
          <Route
            path="/employee/:id"
            element={isAuthenticated ? <EmployeeDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/units/"
            element={isAuthenticated ? <BargainingUnits /> : <Navigate to="/login" />}
          />
          <Route
            path="/unit-info/:id"
            element={isAuthenticated ? <BargainingUnitInfo /> : <Navigate to="/login" />}
          />
          <Route
            path="/towns/"
            element={isAuthenticated ? <Towns /> : <Navigate to="/login" />}
          />
          <Route
            path="/town/:town"
            element={isAuthenticated ? <Town /> : <Navigate to="/login" />}
          />
          <Route
            path="/unassigned-units"
            element={isAuthenticated ? <UnassignedUnits /> : <Navigate to="/login" />}
          />
          <Route
            path="/audit-log"
            element={isAuthenticated ? <AuditLog /> : <Navigate to="/login" />}
          />

          {/* Catch-All Redirect */}
          <Route path="*" element={<Navigate to={isAuthenticated ? '/metrics' : '/login'} />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
