import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../navigation/Nav";
import EditableTableRow from "./../general/EditableTableRow";
import { useAuth } from "./../general/AuthContext";
import "./../styles/units/Unit.css";
import "./../styles/general/Grid.css";




function formatDate(dateString) {
  if (!dateString) {
    return null;  // Return null instead of warning
  }
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid date value:", dateString);
    return null;
  }
  
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}


// Helper function for date formatting
const initialEditData = {
  RepAssigned1: "",
  RepAssigned2: "",
  RepAssigned1_Id: "",
  RepAssigned2_Id: "",
  ServiceFieldRep1: "",
  ServiceFieldRep2: "",
  ServiceFieldRep1_Id: "",
  ServiceFieldRep2_Id: "",
  FieldRep1: "",
  FieldRep1_Id: "",
  Organizer: "",
  Organizer_Id: "",
  LOCAL: "",
  UNIT: "",
  BARGAINING_UNIT: "",
  TOWN: "",
  CBA_START: "",
  CBA_EXP: "",
  PresidentFirstName: "",
  PresidentLastName: "",
  PresidentAddress: "",
  PresidentCity: "",
  PresidentState: "",
  PresidentZipcode: "",
  PresidentHomePhone: "",
  PresidentWorkPhone: "",
  PresidentCellPhone: "",
  PresidentOtherPhone: "",
  PresidentLocalAddress: "",
  VicePresidentFirstName: "",
  VicePresidentLastName: "",
  VicePresidentAddress: "",
  VicePresidentCity: "",
  VicePresidentState: "",
  VicePresidentZipcode: "",
  VicePresidentHomePhone: "",
  VicePresidentWorkPhone: "",
  VicePresidentCellPhone: "",
  VicePresidentOtherPhone: "",
  VicePresidentLocalAddress: "",
  TreasurerFirstName: "",
  TreasurerLastName: "",
  TreasurerCity: "",
  TreasurerState: "",
  TreasurerAddress: "",
  TreasurerZipcode: "",
  TreasurerHomePhone: "",
  TreasurerCellPhone: "",
  TreasurerWorkPhone: "",
  TreasurerOtherPhone: "",
  TreasurerLocalAddress: "",
  RecordingSecretaryFirstName: "",
  RecordingSecretaryLastName: "",
  RecordingSecretaryAddress: "",
  RecordingSecretaryCity: "",
  RecordingSecretaryState: "",
  RecordingSecretaryZipcode: "",
  RecordingSecretaryHomePhone: "",
  RecordingSecretaryWorkPhone: "",
  RecordingSecretaryCellPhone: "",
  RecordingSecretaryOtherPhone: "",
  RecordingSecretaryLocalAddress: "",
  PayrollHRContactName: "",
  PayrollHRContactEmail: "",
  PayrollHRContactNumber: "",
  NewRecommendedContractLanguage: "",
  AreOrientationsHappening: "",
  WhoDoesOrientation: "",
  ActiveJobCount: "",
  TotalDuesPayers: "",
  OnCentralizedDues: "",
  DuesMonthApplied: "",
  DuesFrequency: "",
  NumberOfHomeEmails: "",
  NumberOfPhoneNumbers: "",
  DateLastBargUnitListReceived: "",
  Refusals: "",
  AuditCompleteOverview: "",
  DateAudited: "",
  PEOPLEContributors: "",
  TotalOPTOUTS: "",
  RecommitsSaves: "",
  DateLastOfficerUpdate: "",
  LastElection: "",
  IUMonthYearNextElection: "",
  LengthOfTerm: "",
  Notes: ""
};


// Initial states
const initialBargainingUnitInfoData = {
  membershipInformation: {},
  payrollInformation: {},
  contractsAndNegotiations: {},
  membershipMetrics: {},
  auditsAndCompliance: {},
  financialMetrics: {},
  electionAndOfficerInformation: {},
  additionalNotes: {},
  localPresident: {},
  localVicePresident: {},
  localTreasurer: {},
  localSecretary: {}
};

function BargainingUnitInfo() {


// Router hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();

  // State declarations - all at the top
  const [isLoading, setIsLoading] = useState(true);
  const [unitsData, setUnitsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editData, setEditData] = useState(initialEditData);
  const [BargainingUnitInfoData, setBargainingUnitInfoData] = useState(initialBargainingUnitInfoData);
  const [isEditMode, setIsEditMode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  
  // Reference data states
  const [employees, setEmployees] = useState([]);
  const [towns, setTowns] = useState([]);
  const [staffReps, setStaffReps] = useState([]);
  const [fieldReps, setFieldReps] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [serviceFieldReps, setServiceFieldReps] = useState([]);


// Add console logging to help debug
 useEffect(() => { 
  console.log('Current editData:', editData);
  }, [editData]);
  // Authentication check effect
  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login");
    }
  }, [auth, navigate]);
   




// Navigation handlers
const goToNextUnit = () => {
  if (!unitsData || unitsData.length <= 1) return;
  
  const nextIndex = currentIndex === unitsData.length - 1 ? 0 : currentIndex + 1;
  const nextUnit = unitsData[nextIndex];
  
  if (nextUnit && nextUnit.id) {
    // Update current index before navigation
    setCurrentIndex(nextIndex);
    // Navigate to the next unit using its ID
    navigate(`/unit-info/${nextUnit.id}`, { replace: true });
  }
};

const goToPreviousUnit = () => {
  if (!unitsData || unitsData.length <= 1) return;
  
  const prevIndex = currentIndex === 0 ? unitsData.length - 1 : currentIndex - 1;
  const prevUnit = unitsData[prevIndex];
  
  if (prevUnit && prevUnit.id) {
    // Update current index before navigation
    setCurrentIndex(prevIndex);
    // Navigate to the previous unit using its ID
    navigate(`/unit-info/${prevUnit.id}`, { replace: true });
  }
};

// Modified useEffect for fetching units data
useEffect(() => {
  const fetchAllUnitsData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch("/api/bargaining-units", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setUnitsData(data);
      
      // Find the current unit's index in the data array
      if (id) {
        const index = data.findIndex(unit => unit.id.toString() === id.toString());
        setCurrentIndex(index >= 0 ? index : 0);
      }
    } catch (error) {
      console.error("Failed to fetch units data:", error);
    }
  };

  fetchAllUnitsData();
}, [id]);



  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        const token = sessionStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          navigate("/login");
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        // Fetch all required data in parallel
        const [
          unitsResponse,
          unitInfoResponse,
          notesResponse,
          staffRepsResponse,
          fieldRepsResponse,
          organizersResponse,
          serviceRepsResponse,
          employeesResponse,
          townsResponse
        ] = await Promise.all([
          fetch("/api/bargaining-units", { headers }),
          fetch(`/api/unit-info/${id}`, { headers }),
          fetch(`/api/notes/${id}`, { headers }),
          fetch("/api/staff-reps", { headers }),
          fetch("/api/field-reps", { headers }),
          fetch("/api/organizers", { headers }),
          fetch("/api/service-reps", { headers }),
          fetch("/api/allemployees", { headers }),
          fetch("/api/towns", { headers })
        ]);

        // Process all responses
        const unitsData = await unitsResponse.json();
        const unitInfoData = await unitInfoResponse.json();
        const notesData = await notesResponse.json();
        const staffRepsData = await staffRepsResponse.json();
        const fieldRepsData = await fieldRepsResponse.json();
        const organizersData = await organizersResponse.json();
        const serviceRepsData = await serviceRepsResponse.json();
        const employeesData = await employeesResponse.json();
        const townsData = await townsResponse.json();

        // Update all state
        setUnitsData(unitsData);
        const currentIndex = unitsData.findIndex(unit => unit.id.toString() === id);
        setCurrentIndex(currentIndex >= 0 ? currentIndex : 0);
        
        setBargainingUnitInfoData(unitInfoData);
        setEditData(unitInfoData);
        setNotes(notesData);
        setStaffReps(staffRepsData);
        setFieldReps(fieldRepsData);
        setOrganizers(organizersData);
        setServiceFieldReps(serviceRepsData);
        setEmployees(employeesData);
        setTowns(townsData);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [id, navigate]);

   if (isLoading) {
    return <div>Loading...</div>;
  }
/*
   useEffect(() => {
    // Fetch notes from the API
    fetch(`/api/notes/${id}`)
      .then((response) => response.json())
      .then((data) => setNotes(data))
      .catch((error) => console.error("Failed to fetch notes:", error));
  }, [id]);
*/
// Notes handlers
  const handleNoteChange = (index, value) => {
    setNotes(notes.map((note, i) => 
      i === index ? { ...note, note: value } : note
    ));
  };

  const addNote = async () => {
    try {
      const newNoteObject = {
        bargainingUnitId: id,
        note: newNote,
        datetime: new Date().toISOString(),
      };

      const response = await fetch("/api/notes", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        body: JSON.stringify(newNoteObject),
      });

      const data = await response.json();
      setNotes([...notes, data]);
      setNewNote("");
    } catch (error) {
      console.error("Failed to add note:", error);
    }
  };

  const deleteNote = async (noteId, index) => {
    try {
      const response = await fetch(`/api/notes/${noteId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        setNotes(notes.filter((_, i) => i !== index));
      } else {
        throw new Error("Failed to delete the note.");
      }
    } catch (error) {
      console.error("Failed to delete note:", error);
    }
  };


  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };


const handleSave = async () => {
  try {
    const token = sessionStorage.getItem("token");
    const username = sessionStorage.getItem("username");
    
    if (!token || !username) {
      alert('Session expired or missing auth details. Please login again.');
      navigate("/login");
      return;
    }

    // Make sure we properly format the data with the unit_id
    const saveData = {
      ...editData,
      username,
      urlUnitId: id,  // Add this line
      bodyUnitId: id, // Add this line
      unit_id: id,    // Add this line
      updatedBy: username,
      // Make sure we handle dates properly
      CBA_START: editData.CBA_START || null,
      CBA_EXP: editData.CBA_EXP || null,
      DateLastOfficerUpdate: editData.DateLastOfficerUpdate || null,
      LastElection: editData.LastElection || null,
      IUMonthYearNextElection: editData.IUMonthYearNextElection || null,
      DateLastBargUnitListReceived: editData.DateLastBargUnitListReceived || null,
      AuditCompleteOverview: editData.AuditCompleteOverview || null,
    };

    console.log('Saving data:', saveData);

    const response = await fetch(`/api/unit-info/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(saveData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || 'Failed to save data');
    }

    // Reload data to refresh the view
    await fetchUnitData();
    setIsEditMode(false);
    //alert('Data saved successfully');

  } catch (error) {
    console.error("Save error:", error);
    alert(`Error saving: ${error.message}`);
  }
};


const handleInputChange = (e) => {
  const { name, value, idKey, idValue } = e.target;
  console.log(`Handling input change:`, { name, value, idKey, idValue });

  setEditData(prevData => {
    const newData = { ...prevData };

    // Handle dropdowns with IDs
    if (idKey) {
      newData[name] = value;
      newData[idKey] = idValue;
      
      // Special case for staff rep
      if (name === 'RepAssigned1') {
        newData.STAFF_ASSIGNED = value;
        newData.STAFF_ASSIGNED_ID = idValue;
      }
    }
    // Handle numeric fields
    else if (['ActiveJobCount', 'TotalDuesPayers', 'NumberOfHomeEmails', 'NumberOfPhoneNumbers'].includes(name)) {
      newData[name] = value === '' ? null : parseInt(value, 10);
    }
    // Handle date fields
    else if (name.toLowerCase().includes('date') || name.includes('CBA_')) {
      newData[name] = value ? new Date(value).toISOString() : null;
    }
    // Handle all other fields
    else {
      newData[name] = value === '' ? null : value;
    }

    console.log('Updated data:', newData);
    return newData;
  });
};


const fetchUnitData = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      navigate("/login");
      return;
    }

    const response = await fetch(`/api/unit-info/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (!data) {
      throw new Error('No data returned from server');
    }

    // Update both state objects with the new data
    setBargainingUnitInfoData(data);
    setEditData(data);

  } catch (error) {
    console.error("Error fetching updated data:", error);
    alert(`Error refreshing data: ${error.message}`);
  }
};
return (
    <>
      <Nav />
      <div className="infoContainer">
        {/* Navigation Controls */}
        <div className="navigationContainer">
          <div className="prevButtonContainer">
            <button 
              onClick={goToPreviousUnit}
              disabled={unitsData.length <= 1}
            >
              &lt; Previous
            </button>
            {unitsData.length > 0 && currentIndex > 0 && (
              <div className="unitName">
                {unitsData[currentIndex - 1]?.NAME || ''}
              </div>
            )}
          </div>

          <div className="nextButtonContainer">
            <button 
              onClick={goToNextUnit}
              disabled={unitsData.length <= 1}
            >
              Next &gt;
            </button>
            {unitsData.length > 0 && currentIndex < unitsData.length - 1 && (
              <div className="unitName">
                {unitsData[currentIndex + 1]?.NAME || ''}
              </div>
            )}
          </div>
        </div>


       <div className="action-buttons">
          <span
            style={{
              fontSize: "20px",
              cursor: "pointer",
              padding: "11px",
              textAlign: "left",
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: "red",
              borderRadius: "8px",
            }}
            onClick={handleEditToggle}
          >
            {isEditMode ? "Cancel" : "Edit Unit Information"}
          </span>

          {isEditMode && (
            <span
              onClick={handleSave}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                padding: "10px",
                textAlign: "center",
                backgroundColor: "lightgreen",
                width: "100px",

                borderRadius: "8px",
                marginLeft: "10px",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Save
            </span>
          )}
        </div>
        <div className="employee_info">
          <h1>{editData.RepAssigned1}</h1>
          <h1>{editData.RepAssigned2}</h1>
          <h1>{`${editData.LOCAL} - ${editData.UNIT.toString().padStart(
            4,
            "0"
          )} - ${editData.BARGAINING_UNIT}`}</h1>
        </div>
      </div>

      <div className="container">
        <div
          className="grid-container"
          style={
            isEditMode
              ? {
                  backgroundColor: "#f6ffae",
                  border: "2px solid #eee",
                  padding: "5px",
                  borderRadius: "8px",
                }
              : { backgroundColor: "#fff", padding: "5px" }
          }
        >
          <div className="grid-item">
            <h4>Membership Information </h4>
            <table>
              <tbody>
                <EditableTableRow
                  label="Staff Rep"
                  value={editData.RepAssigned1 || ""}
                  name="RepAssigned1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={staffReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId1: editData.RepAssigned1_Id }}
                />
                <EditableTableRow
                  label="Service Rep"
                  value={editData.ServiceFieldRep1 || ""}
                  name="ServiceFieldRep1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={serviceFieldReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId2: editData.ServiceFieldRep1_Id }}
                />

                <EditableTableRow
                  label="Field Rep"
                  value={editData.FieldRep1 || ""}
                  name="FieldRep1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={fieldReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId3: editData.FieldRep1_Id }}
                />

                <EditableTableRow
                  label="Organizer"
                  value={editData.Organizer || ""}
                  name="Organizer"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={organizers.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId4: editData.Organizer_Id }}
                />

                <EditableTableRow
                  label="Staff Rep 2"
                  value={editData.RepAssigned2 || ""}
                  name="RepAssigned2"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={staffReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId5: editData.RepAssigned2_Id }}
                />

                {/* <EditableTableRow label="Local" value={editData.LOCAL || ''} name="LOCAL" onChange={handleInputChange} isEditMode={isEditMode} />

								<EditableTableRow label="Chapter" value={editData.UNIT || ''} name="UNIT" onChange={handleInputChange} isEditMode={isEditMode} />

								<EditableTableRow label="Bargaining Unit" value={editData.BARGAINING_UNIT || ''} name="BARGAINING_UNIT" onChange={handleInputChange} isEditMode={isEditMode} />
                <EditableTableRow
                  label="Town"
                  value={editData.TOWN || ""}
                  name="TOWN"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={towns.map((town) => ({
                    id: town.Name,
                    employee_full_name: town.Name,
                  }))}
                />

                  */}
                <EditableTableRow
                  label="CBA_START"
                  value={formatDate(editData.CBA_START) || ""}
                  name="CBA_START"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDate={true}
                />

                <EditableTableRow
                  label="CBA_EXP"
                  value={formatDate(editData.CBA_EXP) || ""}
                  name="CBA_EXP"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDate={true}
                />
              </tbody>
            </table>
          </div>

          <div className="grid-item">
            <h4>Payroll and HR Contacts</h4>

            <table>
              <EditableTableRow
                label="Payroll Contact Name"
                value={editData.PayrollHRContactName || ""}
                name="PayrollHRContactName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Payroll Contact Email"
                value={editData.PayrollHRContactEmail || ""}
                name="PayrollHRContactEmail"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Payroll Contact Number"
                value={editData.PayrollHRContactNumber || ""}
                name="PayrollHRContactNumber"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Contract and Negotiations */}
          <div className="grid-item">
            <h4>Contract and Negotiations </h4>
            <table>
              <EditableTableRow
                label="New Recommended Contract Language"
                value={editData.AreOrientationsHappening || ""}
                name="AreOrientationsHappening"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Are Orientations Happening"
                value={editData.NewRecommendedContractLanguage || ""}
                name="NewRecommendedContractLanguage"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Who Does Orientation"
                value={editData.WhoDoesOrientation || ""}
                name="WhoDoesOrientation"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Membership Metrics */}
          <div className="grid-item">
            <h4>Membership and Metrics </h4>
            <table>
              <EditableTableRow
                label="Active Job Count"
                value={editData.ActiveJobCount || ""}
                name="ActiveJobCount"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              {/* Add other fields as necessary 
              <EditableTableRow
                label="Who does Orientations"
                value={editData.WhoDoesOrientation || ""}
                name="WhoDoesOrientation"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              */}
              <EditableTableRow
                label="Total Dues Payers"
                value={editData.TotalDuesPayers || ""}
                name="TotalDuesPayers"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="On Centralized Dues?"
                value={editData.OnCentralizedDues || ""}
                name="OnCentralizedDues"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Dues Month Applied"
                value={editData.DuesMonthApplied || ""}
                name="DuesMonthApplied"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Dues Frequency"
                value={editData.DuesFrequency || ""}
                name="DuesFrequency"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Number of Home Emails"
                value={editData.NumberOfHomeEmails || ""}
                name="NumberOfHomeEmails"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Number of Phone Numbers"
                value={editData.NumberOfPhoneNumbers || ""}
                name="NumberOfPhoneNumbers"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Audits and Compliance */}
          <div className="grid-item">
            <h4>Audits and Compliance </h4>
            <table>
              <EditableTableRow
                label="Date Last Barg Unit List Received"
                value={formatDate(editData.DateLastBargUnitListReceived) || ""}
                name="DateLastBargUnitListReceived"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="Refusals"
                value={editData.Refusals || ""}
                name="Refusals"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Audit Complete Overview"
                value={formatDate(editData.AuditCompleteOverview) || ""}
                name="AuditCompleteOverview"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
            </table>
          </div>

          {/* Financial Metrics */}
          <div className="grid-item">
            <h4>Financial Metrics </h4>

            <table>
              <EditableTableRow
                label="PEOPLE Contributors"
                value={editData.PEOPLEContributors || ""}
                name="PEOPLEContributors"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Recomits/Saves"
                value={editData.RecommitsSaves || ""}
                name="RecommitsSaves"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Total Opt Outs"
                value={editData.TotalOPTOUTS || ""}
                name="TotalOPTOUTS"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          <div className="grid-item">
            <h4>Election and Officer Information </h4>
            <table>
              <EditableTableRow
                label="Date of Last Officer Update"
                value={formatDate(editData.DateLastOfficerUpdate) || ""}
                name="DateLastOfficerUpdate"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="Date of Last Election"
                value={formatDate(editData.LastElection) || ""}
                name="LastElection"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="IU Year of Next Election"
                value={formatDate(editData.IUMonthYearNextElection) || ""}
                name="IUMonthYearNextElection"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />

              <EditableTableRow
                label="Lenghth of Term"
                value={editData.LengthOfTerm || ""}
                name="LengthOfTerm"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              {/* President Information 
              <EditableTableRow
                label="President First Name"
                value={editData.PresidentFirst || ""}
                name="PresidentFirst"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="President Last Name"
                value={editData.PresidentLast || ""}
                name="PresidentLast"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="President Contact Number"
                value={editData.PresidentContactNumber || ""}
                name="PresidentContactNumber"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="President Email"
                value={editData.PresidentEmail || ""}
                name="PresidentEmail"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            */}
            </table>
          </div>

          <div className="grid-item">
            <h4>Local President</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.PresidentFirstName || ""}
                name="PresidentFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.PresidentLastName || ""}
                name="PresidentLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.PresidentAddress || ""}
                name="PresidentAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.PresidentCity || ""}
                name="PresidentCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.PresidentState || ""}
                name="PresidentState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.PresidentZipcode || ""}
                name="PresidentZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.PresidentCellPhone || ""}
					name="PresidentCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.PresidentHomePhone || ""}
                name="PresidentHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.PresidentWorkPhone || ""}
                name="PresidentWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.PresidentOtherPhone || ""}
                name="PresidentOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.PresidentLocalAddress || ""}
                name="PresidentLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
</div>
				<div className="grid-item">
            <h4>Local Vice President</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.VicePresidentFirstName || ""}
                name="VicePresidentFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.VicePresidentLastName || ""}
                name="VicePresidentLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
  <EditableTableRow
                label="Street"
                value={editData.VicePresidentAddress || ""}
                name="VicePresidentAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.VicePresidentCity || ""}
                name="VicePresidentCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.VicePresidentState || ""}
                name="VicePresidentState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.VicePresidentZipcode || ""}
                name="VicePresidentZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.VicePresidentCellPhone || ""}
					name="VicePresidentCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.VicePresidentHomePhone || ""}
                name="VicePresidentHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.VicePresidentWorkPhone || ""}
                name="VicePresidentWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.VicePresidentOtherPhone || ""}
                name="VicePresidentOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.VicePresidentLocalAddress || ""}
                name="VicePresidentLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
</div>
<div className="grid-item">
            <h4>Local Treasurer</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.TreasurerFirstName || ""}
                name="TreasurerFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.TreasurerLastName || ""}
                name="TreasurerLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.TreasurerAddress || ""}
                name="TreasurerAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />


			<EditableTableRow
                label="City"
                value={editData.TreasurerCity || ""}
                name="TreasurerCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.TreasurerState || ""}
                name="TreasurerState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.TreasurerZipcode || ""}
                name="TreasurerZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.TreasurerCellPhone || ""}
					name="TreasurerCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.TreasurerHomePhone || ""}
                name="TreasurerHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.TreasurerWorkPhone || ""}
                name="TreasurerWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.TreasurerOtherPhone || ""}
                name="TreasurerOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.TreasurerLocalAddress || ""}
                name="TreasurerLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
				</div>
				<div className="grid-item">
            <h4>Local Secretary</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.RecordingSecretaryFirstName || ""}
                name="RecordingSecretaryFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.RecordingSecretaryLastName || ""}
                name="RecordingSecretaryLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.RecordingSecretaryAddress || ""}
                name="RecordingSecretaryAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.RecordingSecretaryCity || ""}
                name="RecordingSecretaryCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.RecordingSecretaryState || ""}
                name="RecordingSecretaryState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.RecordingSecretaryZipcode || ""}
                name="RecordingSecretaryZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.RecordingSecretaryCellPhone || ""}
					name="RecordingSecretaryCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.RecordingSecretaryHomePhone || ""}
                name="RecordingSecretaryHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.RecordingSecretaryWorkPhone || ""}
                name="RecordingSecretaryWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.RecordingSecretaryOtherPhone || ""}
                name="RecordingSecretaryOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.RecordingSecretaryLocalAddress || ""}
                name="RecordingSecretaryLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
			{/*
			<EditableTableRow
			label="Vice President First Name"
			value={editData.VicePresidentFirstName || ""}
			  name="VicePresidentFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Vice President Last Name"
			  value={editData.VicePresidentLastName || ""}
			  name="VicePresidentLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
              <EditableTableRow
			  label="Treasurer First Name"
			  value={editData.TreasurerFirstName || ""}
			  name="TreasurerFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Treasurer Last Name"
			  value={editData.TreasurerLastName || ""}
			  name="TreasurerLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Secretary First Name"
			  value={editData.RecordingSecretaryFirstName || ""}
			  name="RecordingSecretaryFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Secretary Last Name"
			  value={editData.RecordingSecretaryLastName || ""}
			  name="RecordingSecretaryLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			*/}
			</div>

          {/* Additional Notes */}
          <div
            className="grid-item"
            style={{ textAlign: "left", overflow: "auto", maxHeight: "350px" }}
          >
            <h4>Notes</h4>
            <table
              style={{ textAlign: "left", overflow: "auto", height: "80%" }}
            >
              {notes.map((note, index) => (
                <tr key={note.id}>
                  <td style={{ textAlign: "left", width: "30%" }}>
                    {formatDate(note.datetime)}
                  </td>
                  <td>
                    {isEditMode ? (
                      <input
                        style={{ width: "90%" }}
                        type="text"
                        value={note.note}
                        onChange={(e) =>
                          handleNoteChange(index, e.target.value)
                        }
                      />
                    ) : (
                      note.note + " [ " + note.employeeName + " ]"
                    )}
                  </td>
                  {isEditMode && (
                    <td style={{ width: "10%" }}>
                      <button onClick={() => deleteNote(note.id, index)}>
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </table>
            {isEditMode && (
              <div>
                <input
                  style={{ width: "75%", padding: "5px", marginTop: "10px" }}
                  type="text"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Add a new note"
                />
                <button
                  style={{
                    float: "right",
                    padding: "3px",
                    marginTop: "10px",
                    borderRadius: "2px",
                    backgroundColor: "lightgreen",
                  }}
                  onClick={addNote}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default BargainingUnitInfo;
