import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useState, useEffect } from 'react';
import './../styles/Home.css';
//import logo from '../images/DH_logo.png';

function NavBar() {
    const [username, setUsername] = useState('');
    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        const storedUsername = sessionStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    // Function to handle logout
    const handleLogout = () => {
        sessionStorage.clear();  // Clear session storage
        navigate('/login');       // Redirect to login page
    };

    return (
        <div>
            <nav>
			
                <ul className="navPage_nav-links">
                    <li className="navPage_nav-links"> <img src="/images/DH_logo.png" alt="DATAHOUSE Logo" className="nav-logo" style={{width:'100px'}}/>
</li>
                    <li className="navPage_list">
                        <Link to="/staff">Staff</Link>
                    </li>
                    <li className="navPage_list">
                        <Link to="/units">Units</Link>
                    </li>
                    <li className="navPage_list">
                        <Link to="/towns">Towns</Link>
                    </li>
                    <li className="navPage_list">
                        <Link to="/unassigned-units">Unassigned Units</Link>
                    </li>
                    <li className="navPage_list">
                        <Link to="/metrics">Metrics</Link>
                    </li>
                    <li className="navPage_list">
                        <Link to="/audit-log">Audit Logs</Link>
                    </li>
                    <li className="navPage_list">
                        <a href="#" onClick={handleLogout}>Logout</a> {/* Use onClick handler to trigger logout */}
                    </li>

                        <li className="navPage_list">
							<span style={{fontSize:'15px'}}> {username ? `${username} - Maintainer` : "Loading..."} </span> 
						</li>

                </ul>
            </nav>
        </div>
    );
}

export default NavBar;
