import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Nav from './../navigation/Nav';

function EmployeeDetails() {
    const { id } = useParams();
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'ascending'
    });

    useEffect(() => {
        fetch(`/api/employee/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Employee Details:', data);
                setEmployeeDetails(data);
            })
            .catch(error => console.error('Error fetching employee details:', error));
    }, [id]);

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...employeeDetails].sort((a, b) => {
            let aVal = a[key];
            let bVal = b[key];

            if (key === 'UnitChapter') {
                aVal = aVal !== null && aVal !== undefined ? parseInt(aVal) : -1;
                bVal = bVal !== null && bVal !== undefined ? parseInt(bVal) : -1;
            }

            if (aVal < bVal) return direction === 'ascending' ? -1 : 1;
            if (aVal > bVal) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setEmployeeDetails(sortedData);
    };

    if (!employeeDetails) {
        return (
            <>
                <Nav />
                <div className="text-3xl mx-auto w-4/5 text-center mt-40 font-bold">
                    No info for the employee with ID: {id}. Report to Webmaster jonny@two-bit-operation.com.
                </div>
            </>
        );
    }

    if (employeeDetails.length === 0) {
        return (
            <>
                <Nav />
                <div className="text-3xl mx-auto w-4/5 text-center mt-40 font-bold">
                    Employee does not have any Units under them. <Link to={'/staff/'}>Go back to Staff page</Link>
                </div>
            </>
        );
    }

    const columns = [
        { key: 'UnitNumber', label: 'Local' },
        { key: 'UnitChapter', label: 'Chapter' },
        { key: 'UnitName', label: 'Bargaining Unit' },
        { key: 'Town', label: 'Town' }
    ];

    return (
        <div>
            <Nav />
            <div className="employeeInformation">
                <h1 className="p-5 text-4xl ml-12">
                    {employeeDetails[0]?.employee_full_name}
                </h1>
            </div>
            <table className="w-4/5 border-collapse mx-auto text-base mt-16">
                <thead>
                    <tr className="bg-gray-100">
                        {columns.map(column => (
                            <th 
                                key={column.key}
                                className={`p-3 text-left cursor-pointer hover:bg-gray-200 select-none text-lg whitespace-nowrap ${
                                    sortConfig.key === column.key ? 'font-bold' : ''
                                }`}
                                onClick={() => sortData(column.key)}
                            >
                                <div className="flex items-center gap-2">
                                    {column.label} 
                                    <span className="text-gray-400">↕</span>
                                    {sortConfig.key === column.key && (
                                        <span className="text-gray-500">
                                            {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                                        </span>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {employeeDetails.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                            <td className="p-3">{row.UnitNumber}</td>
                            <td className="p-3">
                                {row.UnitChapter !== null && row.UnitChapter !== undefined 
                                    ? row.UnitChapter.toString().padStart(4, "0") 
                                    : "----"}
                            </td>
                            <td className="p-3">
                                <Link to={`/unit-info/${row.ID}`} className="text-blue-600 hover:underline">
                                    {row.UnitName}
                                </Link>
                            </td>
                            <td className="p-3">
                                <Link to={`/town/${row.Town}`} className="text-blue-600 hover:underline">
                                    {row.Town}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default EmployeeDetails;
